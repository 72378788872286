import React from 'react'
import Lottie from 'lottie-react'
import { Button } from '@mui/material'
import Specialization1 from '../Animations/holisticwellness.json'
import Specialization2 from '../Animations/dietlifstyle.json'
import Specialization3 from '../Animations/monitoring.json'
import Specialization4 from '../Animations/heartpulse.json'
import Specialization5 from '../Animations/enhancinghealth.json'
function Specialization() {
    return <>
        <div className='Specialization-page' id='specialization'>
            <p>OUR SPECIALIZATION</p>
            <div className='Specialization-list'>
                <div>
                    <p>"Tailored Wellness Solutions"</p>
                    <p>Personalised & Holistic Wellness Solutions</p>
                    <p>Customized integrative wellness focuses on individual

                        well-being, considering unique needs, preferences, and

                        circumstances to create holistic, comprehensive plans for

                        physical and mental health</p>
                    <div className='begin-now'>
                        <Button style={{ color: 'white', backgroundColor: "#0076BE",}}
                        onClick={()=>window.open('https://www.app.aiwell.in')} id='sbtn'>Begin now</Button>
                    </div>

                </div>

                <div>
                    <Lottie animationData={Specialization1}></Lottie>
                </div>
            </div>
            <div className='Specialization-list'>
                <div>
                    <p>"Plan Your Personal Wellness"</p>
                    <p>Personalised & customized diet,lifestyle, exercise recommendations.</p>
                    <p>We offer personalized diet, lifestyle, and exercise

                        recommendations tailored to your wellness needs,

                        providing individualized solutions for a healthier

                        and balanced lifestyle.</p>
                    <div className='begin-now'>
                        <Button style={{ color: 'white', backgroundColor: "#0076BE",}}
                         onClick={()=>window.open('https://www.app.aiwell.in')}id='sbtn' >Begin now</Button>
                    </div>

                </div>

                <div>
                    <Lottie animationData={Specialization2}></Lottie>
                </div>
            </div>
            <div className='Specialization-list'>
                <div>
                    <p>"Proactive Health Monitoring"</p>
                    <p>Regular monitoring of health status and

                        early detection of diseases</p>

                    <p>Consistent monitoring of your health enables early disease

                        detection, facilitating timely intervention and effective

                        management, ultimately leading to improved outcomes

                        and potential illness prevention through a proactive approach.</p>
                    <div className='begin-now'>
                        <Button style={{ color: 'white', backgroundColor: "#0076BE",}}
                         onClick={()=>window.open('https://www.app.aiwell.in')}id='sbtn' >Begin now</Button>
                    </div>

                </div>

                <div>
                    <Lottie animationData={Specialization3}></Lottie>
                </div>
            </div>
            <div className='Specialization-list'>
                <div>
                    <p>"Fingertip Pulse Health Analysis"</p>
                    <p>Digital health monitoring through finger tip pulse</p>
                    <p>Using our proprietary fingertip pulse analysis,

                        we offer digital health monitoring. This

                        technology enables the Prakriti & Vikriti analyse

                        facilitating real-time insights and personalized

                        care for individuals.</p>
                    <div className='begin-now'>
                        <Button style={{ color: 'white', backgroundColor: "#0076BE", }}
                         onClick={()=>window.open('https://www.app.aiwell.in')}id='sbtn'>Begin now</Button>
                    </div>

                </div>

                <div>
                    <Lottie animationData={Specialization4}></Lottie>
                </div>
            </div>
            <div className='Specialization-list'>
                <div>
                    <p>"Total Wellness Transformation"</p>
                    <p>Enhancing physical fitness,

                        mental wellness and overall health.</p>

                    <p>Our integrative personalized solutions aim to enhance

                        both physical and mental well-being. By tailoring the

                        personalised recommendations to individual needs, we

                        address the unique aspects of each person's health,

                        promoting overall wellness and balanced living.</p>

                    <div className='begin-now'>
                        <Button style={{ color: 'white', backgroundColor: "#0076BE", }}
                        onClick={()=>window.open('https://www.app.aiwell.in')} id='sbtn'>Begin now</Button>
                    </div>

                </div>

                <div>
                    <Lottie animationData={Specialization5}></Lottie>
                </div>
            </div>
        </div>
    </>
}

export default Specialization