import React, { useState } from 'react'
import { useRef, useEffect } from 'react'
import { Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
function Navbar() {
   let navbar = useRef()
   let scrolleffect = () => {
      window.scrollY > 20 ? navbar.current.style.background = 'white' : navbar.current.style.background = '#F3FFFA'
      window.scrollY > 20 ? navbar.current.style.position = 'fixed' : navbar.current.style.position = 'static'
   }
   let [sidebar, setsidebar] = useState(true)

   const StyledDrawer = styled(Drawer)(({ theme }) => ({
      '& .MuiPaper-root': {
         background: 'rgba(80, 227, 194, 0.45)',
         color: "white",
         boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
         backdropFilter: 'blur(4px)',
         webkitBackdropFilter: 'blur(4px)',
         border: '1px solid rgba(255, 255, 255, 0.18)',
      },
   }));

   const showSidebar = () => setsidebar(!sidebar)
   useEffect(() => {
      window.addEventListener("scroll", scrolleffect)
   }, [])

   const [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
   });

   const toggleDrawer = (anchor, open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setState({ ...state, [anchor]: open });
   };

   const list = (anchor) => (
      <Box
         sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
         role="presentation"
         onClick={toggleDrawer(anchor, false)}
         onKeyDown={toggleDrawer(anchor, false)}

      >
         <List style={{
            padding: "1rem",
         }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", transition: "ease-in" }}>
               <CloseIcon />
            </div>
            <ListItem>
               <ListItemButton>
                  <ListItemText ><a href='#abt' style={{ textDecoration: "none", color: "white" }}><p id='Sidebar-text'>About us</p></a></ListItemText>
               </ListItemButton>
            </ListItem>
            <ListItem>
               <ListItemButton>
                  <ListItemText><a href='#Services' style={{ textDecoration: "none", color: "white" }}><p id='Sidebar-text' >Key features</p></a></ListItemText>
               </ListItemButton>
            </ListItem>
            <ListItem>
               <ListItemButton>
                  <ListItemText><a href='#specialization' style={{ textDecoration: "none", color: "white" }}><p id='Sidebar-text'>Our Specialization</p></a></ListItemText>
               </ListItemButton>
            </ListItem>
            <ListItem>
               <ListItemButton>
                  <ListItemText><a href='#articles' style={{ textDecoration: "none", color: "white" }}><p id='Sidebar-text'>Articles</p></a></ListItemText>
               </ListItemButton>
            </ListItem>
            <ListItem>
               <ListItemButton>
                  <Button
                     style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                     }}
                     onClick={() => {
                        window.open('https://v2.aiwell.in/BetaSignup')
                     }}
                  >
                     Signup now
                  </Button>
               </ListItemButton>
            </ListItem>
         </List>
      </Box>
   );


   return <>
      <div className='Navbar' ref={navbar}>
         <div className='Logo' onClick={() => window.location.href = "#"}>
            <img src='aiwell.png' alt='Logo'></img>
         </div>
         <nav className='Navbar-contents' >
            <a href='#abt' style={{ textDecoration: "none", }} ><p>About us</p></a>|
            <a href='#Services' style={{ textDecoration: "none", }}><p>Key features</p></a>|
            <a href='#specialization' style={{ textDecoration: "none", }} ><p>Our Specialization</p></a>|
            <a href='#articles' style={{ textDecoration: "none", }}  ><p>Articles</p></a>
            {/* <Button style={{ textTransform: "none", backgroundColor: "#0076BE", color: "white" }}
            id='signupbtn'
             onClick={()=>window.open('https://www.app.aiwell.in')}>Sign up</Button> */}
         </nav>
         <div id='menubtn'>
            <MenuIcon onClick={toggleDrawer('right', true)}></MenuIcon>
         </div>
         <StyledDrawer
            anchor={'right'}
            open={state["right"]}
            onClose={toggleDrawer('right', false)}
         >
            {list('right')}
         </StyledDrawer>
      </div>
   </>
}

export default Navbar