import React, { useState } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { getDatabase, ref, push } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { Navigate, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import { toast, Zoom } from 'react-toastify';
import Lottie from 'lottie-react';
import un from './Animation - 1702536743411.json'
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function Unsubsribe() {
    const [otherReasons, setOtherReasons] = useState('')
    const [open, setOpen] = useState(false);
    const [pkglist, setpkglist] = useState(new Map())
    const {email}=useParams()
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        window.history.back()
        setOpen(false);
    };

 

    const Navigate = useNavigate()

    const UnSubscribe = async () => {
        if (email != '') {
            if (pkglist.size != 0) {
                try {
                    const Reason = otherReasons != '' ? [...Array.from(pkglist.values()), otherReasons] : Array.from(pkglist.values())
                    const UnSubscribe = await axios.post('https://api.ayurai.in/api/data/unSubscribe', {
                        Email: email,
                        Reason
                    })
                    if (UnSubscribe.status === 200) {
                        handleClickOpen()
                        setTimeout(()=>{
                         Navigate('/')
                        },2000)
                    }
                }
                catch (err) {
                    if (err?.response?.status === 400) {
                        toast.error(err.response.data, {
                            position: "top-center",
                            limit: 1,
                            transition: Zoom,
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark"
                        })
                    }
                    console.log(err)
                }
            }
            else {

                toast.info("Please Select any reason", {
                    position: "top-center",
                    limit: 1,
                    transition: Zoom,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                })

            }

        }
        else {

            toast.info("Please Enter Your Email", {
                position: "top-center",
                limit: 1,
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
            })

        }

    }

    const AddPackages = (key, value) => {
        setpkglist((prev) => {
            const newpkg = new Map(prev);
            if (newpkg?.has(key)) {
                newpkg?.delete(key);
            }
            else {
                newpkg?.set(key, value);
            }
            return newpkg;
        });
    }

    const options = [
        { Name: "Too many emails" },
        { Name: "Irrelevant content" },
        { Name: "Promotional emails" },
        { Name: "No longer use service/product" },
        { Name: "Prefer not to disclose" },
        { Name: "Other (please specify)" }
    ]

    return <>
        <div className='Navbar2' style={{ backgroundColor: "white" }}>
            <div className='Logo' onClick={() => window.location.href = "#"}>
                <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/ayurailogofull.webp' alt='Logo'></img>
            </div>
            <div className='Social-icons'>
                <div>
                    <FacebookIcon onClick={() => window.open('https://www.facebook.com/ayuraiinnovations')} />
                </div>
                <div>
                    <InstagramIcon onClick={() => window.open('https://www.instagram.com/ayur.ai_/')} />
                </div>
                <div><i class="fa-brands fa-linkedin-in" onClick={() => window.open('https://www.linkedin.com/company/ayurai/')} ></i>   </div>
                <div>
                    <i class="fa-brands fa-x-twitter" onClick={() => window.open('https://twitter.com/Ayur_AI')}></i>
                </div>
            </div>
        </div>
        <div className='re-page'>
            <p>We Hope to See You Again </p>
            <p>Help Us Improve! Please Tell Us Why You're Unsubscribing.</p>
            <div>
                {
                    options.map((e) => {
                        return <div className='Options-list'>
                            <input type='checkbox' checked={pkglist?.has(e.Name) ? true : false} onChange={() => {
                                AddPackages(e.Name, e.Name)
                            }}></input>
                            <p>{e.Name}</p>
                        </div>
                    })
                }
                {
                    pkglist.has("Other (please specify)") &&
                    <textarea className='Textarea' rows={8} placeholder='Please specify' onChange={(e) => {
                        setOtherReasons(e.target.value)
                    }}>

                    </textarea>
                }
            </div>
            <Button style={{ textTransform: "none", backgroundColor: "red", color: "white" }} className='Registerbtn' onClick={UnSubscribe}>
                UnSubscribe
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogContent dividers>
                    <div >
                        <Lottie animationData={un} />
                        <p id='untext'>You have unsubscribed from our email newsletter</p>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} style={{ textTransform: "none", backgroundColor: "#0076BE", color: "white" }}>
                        Ok
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    </>
}

export default Unsubsribe