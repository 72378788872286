import React from 'react'
import Lottie from 'lottie-react'
import { Link } from '@mui/material'
import playstoreicon from '../Animations/MicrosoftTeams-image.png'
import index_img from '../Animations/Index without background.json'
import 'animate.css';
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
function Main() {
  const Navigate = useNavigate()
  return (
    <div id='main'>
      <div className='Content'>
        <p id='heading'>
          Ayurvedic Wellness
        </p>
        <p>Transforming Ayurveda for the Modern Era:<br /> Scientifically

          Grounded Ayurveda Tailored for Individual Health, Well being,

          and Integrative Healthcare</p>
        <p>Take a <span >2-min Ayurvedic baseline </span>(Prakriti) assessment. Click  <a href='https://www.app.aiwell.in/' style={{ color: "#47BF91", textDecoration: "none" }}>here</a>.</p>
        <div className='Download-btns'>
          <Link href='https://play.google.com/store/search?q=aiwell&c=apps&hl=en-IN' target='_blank' style={{ textDecoration: "none" }}>
            <div id='play-btn'>
              <img src={playstoreicon}></img>&nbsp;&nbsp;Download on Playstore</div>
          </Link>
        </div>
      </div>
      <div className='App_homepage animate__animated animate__backInUp'>
        <Lottie animationData={index_img} />
      </div>
    </div>
  )
}

export default Main