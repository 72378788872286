import React, { useState } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { getDatabase, ref, push } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { Navigate, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function Register() {
    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [mobileno, setmobileno] = useState('')
    const [symptoms, setsymtoms] = useState('')
    const [open, setOpen] = useState(false);
    const [pkglist, setpkglist] = useState(new Map())
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        window.location.reload();
        setOpen(false);
    };

    const Navigate = useNavigate()

    const Register = async () => {
        if (name != '') {
            if (email != '') {
                if (pkglist.size != 0) {

                    const db = getDatabase();
                    const newUserId = uuidv4();
                    const userData = {
                        id: newUserId,
                        Name: name,
                        Email: email,
                        Symptom: symptoms,
                        Phone: mobileno,
                        Packages: Array.from(pkglist.values())
                    }

                    await push(ref(db, 'cooperate_users/'), userData);
                    handleClickOpen()
                }
                else {

                    toast.info("Please Select any Package", {
                        position: "top-center",
                        limit: 1,
                        transition: Zoom,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark"
                    })

                }

            }
            else {

                toast.info("Please Enter Your Email", {
                    position: "top-center",
                    limit: 1,
                    transition: Zoom,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                })

            }

        }
        else {
            toast.info("Please Enter Your Name", {
                position: "top-center",
                limit: 1,
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
            })
        }
    }

    const AddPackages = (key, value) => {

        setpkglist((prev) => {
            const newpkg = new Map(prev);

            if (newpkg?.has(key)) {
                newpkg?.delete(key);

            }

            else {

                newpkg?.set(key, value);

            }
            return newpkg;


        });
    }



    const Packages = [{

        Name: 'Physical',
        Desc: "Physical wellness includes adopting healthy habits like",
        points: ['Exercise', 'Good nutrition', 'Proper sleep', 'Sexual health', 'Stress control'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(38).png"
    },
     {
        Name: "Gastro wellness",
        Desc: "Gastro wellness includes:",
        points: ['Maintaining healthy gut', 'Preventing abdominal discomforts like acidity, bloating', 'Strengthening Immunity'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(36).png"

    }, 
    {

        Name: "Women's wellness",
        Desc: "Women's wellness focuses on ",
        points: ['Hormonal and gynecological issues', 'Bone health and mental health', 'Healthy weight control'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(40).png"

    }, {
        Name: "Mental wellness",
        Desc: "Mental wellness includes:",
        points: ['Ability to handle stress', 'Overcome hardships and challenges', 'Build connections'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(42).png"
    }, {
        Name: "Respiratory",
        Desc: "Respiratory wellness is essential for",
        points: ['Reducing the chances of diseases like asthma, cough, cold, etc', 'Promoting overall health'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(39).png"

    }, {
        Name: "Metabolic",

        Desc: "Metabolic wellness includes:",

        points: ['Prevention of obesity, diabetes, and heart disease', 'Improving BMI, blood sugar control and blood pressure.'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(37).png"
    }, {
        Name: "Hair & Skin",
        Desc: "Hair and skin wellness focuses on:",
        points: ['Maintaining healthier skin and hair', 'Preventing Dandruff and infections'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(35).png"
    }, {
        Name: "Sexual",
        Desc: "Sexual wellness includes:",
        points: ['Improved overall health', 'Preventing Hormonal imbalances'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(41).png"
    }]






    return <>
        <div className='Navbar2' style={{ backgroundColor: "white" }}>

            <div className='Logo' onClick={() => window.location.href = "#"}>
                <img src='aiwell.png' alt='Logo'></img>
            </div>
            <div className='Social-icons'>
                <div>
                    <FacebookIcon onClick={() => window.open('https://www.facebook.com/ayuraiinnovations')} />
                </div>
                <div>
                    <InstagramIcon onClick={() => window.open('https://www.instagram.com/ayur.ai_/')} />
                </div>
                <div><i class="fa-brands fa-linkedin-in" onClick={() => window.open('https://www.linkedin.com/company/ayurai/')} ></i>   </div>
                <div>
                    <i class="fa-brands fa-x-twitter" onClick={() => window.open('https://twitter.com/Ayur_AI')}></i>
                </div>
            </div>
        </div>
        <div className='re-page'>
            <div className='lp'>
                <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/CW1.png'></img>
            </div>
            <p>Signup for a Unique Science Driven Wellness Program</p>
            <TextField id="outlined-basic" label="Name" variant="outlined" onChange={(e) => {
                setname(e.target.value)
            }} />
            <TextField id="outlined-basic" label="Email" variant="outlined" onChange={(e) => {
                setemail(e.target.value)
            }} />

            <TextField id="outlined-basic" label="Mobile Number(Optional)" variant="outlined"
                onChange={(e) => {
                    setmobileno(e.target.value)
                }} />

            <TextField id="outlined-basic" label="Enter Symptoms (Optional)" variant="outlined" 
                onChange={(e) => {
                    setsymtoms(e.target.value)
                }} />
                
            <p id='crp'>Choose Your Relevant Wellness Package</p>
            <div className='pkg-list'>
                {
                    Packages.map((e) => {
                        return <div className='Pkg-card' onClick={() => {
                            AddPackages(e.Name, e.Name)
                        }} style={pkglist?.has(e.Name) ? { filter: "blur(0.8px)" } : {}}>
                            <img src={e.img}></img>
                            <p>{e.Name}</p>
                            <div>
                                <div>
                                    <Tooltip id='tooltip' title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", flexDirection: "column", padding: "1rem" }}><p id='tooltiptext'>{e.Desc}</p>
                                        <ul>{e.points.map((s) => {
                                            return <li>{s}</li>
                                        })}</ul></div>} arrow enterTouchDelay={0} onClick={(e) => {
                                            e.stopPropagation();
                                        }}>
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                {pkglist?.has(e.Name) ?
                                    <div style={pkglist?.has(e.Name) ? { position: "absolute", display: "flex", justifyContent: "center", alignItems: "center" } : { display: "none" }}>
                                        <div style={{ width: "2rem", height: "2rem", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "blue" }}>
                                            <CheckIcon style={{ color: "white" }} />
                                        </div>
                                    </div> : <></>}
                            </div>

                        </div>
                    })
                }
            </div>

            <Button style={{ textTransform: "none", backgroundColor: "#0076BE", color: "white" }} className='Registerbtn' onClick={Register}>
                Register
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div className='congratsdiv'>

                        <p>Congrats for Embracing Integrative Wellness Program</p>
                        <p>
                            Here's to your journey for a healthier and happier you!
                        </p>
                        <div className='Logo' onClick={() => window.location.href = "#"} >
                            <img src='aiwell.png' alt='Logo' style={{ width: "7rem" }}></img>
                        </div>
                        <p>Our team will reach out to you shortly. In the meantime, feel free to explore our wellness application.</p>
                        <Button style={{ textTransform: "none", backgroundColor: "#0076BE", color: "white", marginTop: "2rem" }} className='Registerbtn'
                            onClick={() => { Navigate('/') }}>
                            Explore Now
                        </Button>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} style={{ textTransform: "none", backgroundColor: "#0076BE", color: "white" }}>
                        Ok
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    </>
}

export default Register