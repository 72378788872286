import React from 'react'
// import tele from '../Animations/Teleconsultation 1.json'
import lab from '../Animations/MicrosoftTeams-image (4).png'
import suppletments from '../Animations/MicrosoftTeams-image (3).png'
import doc from '../Animations/MicrosoftTeams-image (5).png'
import bulletin from '../Animations/MicrosoftTeams-image (2).png'
import Lottie from 'lottie-react'
import { Slide } from "react-awesome-reveal";
import { Button } from '@mui/material'
function Services() {
   return <>
      <div className='Sevices-page' id='Services'>
         <p>KEY SERVICES</p>
         <p>Comprehensive  Health <span style={{ color: "#0076BE" }}>Solutions</span></p>
         <div className='services'>
            <div>
               <div className='Nutraceutical'>
                  <p>Teleconsultation Service</p>
                  <p>Consult Our Expert Ayurveda <br />Health Coach</p>
                  <p>Get Upto&nbsp;<span>10% Off</span></p>
                  <div className='begin-now'>
                     <Button style={{ color: 'white', backgroundColor: "#0076BE", }}
                     onClick={()=>window.open('https://www.app.aiwell.in')} id='sbtn'>Book now</Button>
                  </div>
               </div>
               <Slide>
                  <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/Dr+img+for+website.png' id='services-img'></img>
               </Slide>

            </div>

            <div>
               <div className='Nutraceutical'>
                  <p>Ayurveda Nutraceutical Products</p>
                  <p>Unlock Your Vitality with Ayurvedic <br /> Nutraceutical Wonders!</p>
                  <p>Get Upto&nbsp;<span>10% Off</span></p>
                  <div className='begin-now'>
                     <Button style={{ color: 'white', backgroundColor: "#0076BE",}}
                     onClick={()=>window.open('https://www.app.aiwell.in')} id='sbtn'>Book now</Button>
                  </div>
               </div>
               <Slide direction='right'>
                  <img src={suppletments} id='services-img'></img>
               </Slide>
            </div>

            <div>
               <div className='Nutraceutical'>
                  <p>Gut Microbiome Test</p>
                  <p>BugSpeaks: South Asia's First Gut Microbiome <br />
                     Test Kit for Personalized Gut Health</p>
                  <p>Get Upto&nbsp;<span>10% Off</span></p>
                  <div className='begin-now'>
                     <Button style={{ color: 'white', backgroundColor: "#0076BE" }}
                     onClick={()=>window.open('https://www.app.aiwell.in')} id='sbtn'>Book now</Button>
                  </div>
               </div>
               <Slide >
                  <img src={lab} id='services-img'></img>
               </Slide>
            </div>
         </div>
      </div>
   </>
}

export default Services