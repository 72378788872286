import React from 'react'
import MobileScreen from '../Animations/MicrosoftTeams-image (1).png'
import { Button } from '@mui/material'
import { Slide } from "react-awesome-reveal";
function About() {
  return <div className='About-section' id='abt'>
      
    <Slide>
    <div className='Mobile-screen'>
      <img src={MobileScreen}></img>
    </div>
    </Slide>
    <div className='About-section-content'>
      <p>ABOUT <span>AIWELL</span></p>
      <p>Explore the transformative potential of <span style={{ color: "#0076BE", fontWeight: '600' }}>"Ayurveda"</span>
        in the 21st century with us. <span style={{ fontWeight: '600' }} >Discover a new path to

          vibrant health and well-being</span> that is tailored to your

        unique needs and firmly rooted in the latest

        scientific evidence. </p>


      <p>“Join us on this exciting journey toward

        a healthier, more balanced you.”
      </p>
      <div className='begin-now'>
      <Button style={{color:'white',backgroundColor:"#0076BE",borderRadius:"4rem",width:"8rem"}}>Begin now</Button>
    </div>
      </div>
     
  </div>
}

export default About