import './App.css';
import Home from './Compontents/Home';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Register from './Register';
import Unsubsribe from './Compontents/Unsubsribe';
function App() {

  useEffect(() => {
    let params = new URL(document.location).searchParams;
    let emailTemplate = params.get("key");
    if (emailTemplate === "Newsletter") {
      MailTracker('App_Downloads');
    }

  }, []);

  const MailTracker = async (value) => {
    const payload = { key: value };
    try {
      const response = await fetch('https://api.ayurai.in/api/data/NewsLetterMailTracker', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('error:', error);
    }
  };
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Register' element={<Register />} />
        <Route path='/UnSubscribe/:email' element={<Unsubsribe />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
