import React from 'react'
import Navbar from './Navbar';
import Main from './main';
import Services from './Services';
import Specialization from './Specialization';
import Tele from './Tele';
import Articles from './Articles';
import Footer from './Footer';
import About from './About';
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
function Home() {
  const Navigate = useNavigate()  
    return <>
      <div>
          <><Navbar />
          <div className='betaclickhere'>
      <div><p>Be the first to experience innovative features & seamless performance with AiWell Beta launch</p></div>
      <div>
        <Button
          style={{
            width: '100%',
            textTransform: 'none',
            borderRadius: '0',
            backgroundColor: '#DA6E0B',
            color: 'white',
            border: 'none',
            boxSizing: 'border-box',
            paddingRight:"2rem",
            paddingLeft:"2rem",
            borderRadius: "5px",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 500,
            padding: "10px"
          }}
          onClick={() => {
            window.open('https://v2.aiwell.in/BetaSignup')
          }}
        >
          Signup now
        </Button>
      </div>
    </div>
            <Main />
            <About />
            <Services />
            <Specialization />
            <Tele />
            <Articles />
            <Footer />
          </>
      </div>
    </>
}

export default Home