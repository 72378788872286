import React from 'react'
import Doc from '../Animations/Group 5141.png'
import { Button } from '@mui/material'
function Tele() {
  return <div className='tele-page'>

    <div className='tele-part'>
      <div>
        <p>AIWELL</p>
        <p>"Empower your health journey with our

          pioneering Teleconsultation feature. Experience

          accessible and convenient healthcare like never

          before. Join our platform today."
        </p>
        <div className='begin-now'>
          <Button style={{ color: 'white', backgroundColor: "#0076BE", borderRadius: "4rem", width: "8rem" }}>Consult now</Button>
        </div>
      </div>
      <div>
        <img src={Doc}></img>
      </div>
    </div>

  </div>
}

export default Tele